<template>
  <div class="modal-message">
    <transition
      v-if="isOpen"
      name="fade"
    >
      <div
        class="modal-overlay"
        @click="handleOverlayClick"
      >
        <div
          ref="modal"
          :style="{'text-align': 'center', 'max-width': maxWidth}"
          class="modal-content"
        >
          <div class="mobile-content-scroll">
            <div
              v-if="showWarning && greetingCardsEnabled"
              class="warning"
            >
              <span class="warning-title">
                Sua mensagem <b>não será impressa em um cartão lembrança</b>
                como as dos outros convidados
              </span>
              <br>
              <a-legacy-button
                class="warning-button"
                @click.native="addCard()"
              >
                Adicionar cartão
              </a-legacy-button>
            </div>
            <div class="container grid-lg">
              <div
                :style="{'height':contentHeight}"
                class="contents"
              >
                <a-header
                  v-if="isViewportMobile"
                  class="header"
                  color="#808080"
                  text="Voltar"
                  emit-event
                  title-alignment="left"
                  @backButtonClicked="closeModal()"
                />
                <div>
                  <core-box margin="0 0 2">
                    <core-text
                      type="heading"
                      size="small"
                      :align="isViewportDesktop ? 'center' : undefined"
                    >
                      Escolha uma mensagem
                    </core-text>
                  </core-box>
                  <core-text
                    :align="isViewportDesktop ? 'center' : undefined"
                    color="grey-medium"
                  >
                    {{ modalSubtitle }}
                  </core-text>
                </div>

                <div class="columns card-info">
                  <div class="column col-6 col-xs-12">
                    <div class="message-container">
                      <div
                        :class="{'selected': messageIndex === 0}"
                        class="input-check"
                        @click="selectCustomMessage(customMessage)"
                      >
                        <verified-svg/>
                      </div>
                      <div class="message">
                        <div class="custom-label">
                          Personalizada
                        </div>

                        <textarea
                          ref="messageArea"
                          v-model="customMessage"
                          :placeholder="textAreaPlaceholder"
                          class="custom-textarea"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="loading"
                    class="loader"
                  >
                    <a-point-loader position="center"/>
                  </div>
                  <div
                    v-for="(message, index) in displayedMessages"
                    v-else
                    :key="index"
                    class="column col-6 col-xs-12"
                  >
                    <div
                      class="message-container"
                      @click="selectPickedMessage(index)"
                    >
                      <div
                        :class="{'selected': messageIndex === (index+1)}"
                        class="input-check"
                      >
                        <verified-svg/>
                      </div>
                      <core-text
                        size="small"
                        class="message"
                      >
                        {{ message }}
                      </core-text>
                    </div>
                  </div>
                </div>
                <div class="container-footer">
                  <a-legacy-button
                    ref="btn"
                    :disabled="canDisableButton"
                    class="confirm-button"
                    @click.native="confirm()"
                  >
                    Quero essa mensagem!
                  </a-legacy-button>
                </div>
              </div>
            </div>

            <div
              v-if="!isViewportMobile"
              class="close-button"
              @click="closeModal"
            >
              <close-svg/>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import VerifiedSvg from '@wedy/legacy-assets/images/verified.svg';
import CloseSvg from '@wedy/legacy-assets/images/icons/close.svg';
import APointLoader from '../atoms/a-point-loader.vue';
import CoreBox from '../core/core-box.vue';
import CoreText from '../core/core-text.vue';

import viewportly from '../mixins/viewportly';
import ALegacyButton from '../atoms/a-legacy-button.vue';
import AHeader from '../atoms/a-header.vue';

export default {
  name: 'MMessageModal',
  components: {
    APointLoader,
    ALegacyButton,
    AHeader,
    CoreBox,
    CoreText,
    VerifiedSvg,
    CloseSvg,
  },
  mixins: [
    viewportly,
  ],
  props: {
    showWarning: {
      default: false,
      type: Boolean,
    },
    greetingCardsEnabled: {
      default: false,
      type: Boolean,
    },
    closeEnabled: {
      default: true,
      type: Boolean,
    },
    listeningTo: {
      default: 'openMessageModal',
      type: String,
    },
    maxWidth: {
      type: String,
      default: '860px',
    },
  },
  data() {
    return {
      isOpen: false,
      loading: false,
      messages: [],
      displayedMessages: [],
      customMessage: '',
      pickedMessage: '',
      messageIndex: 0,
      contentHeight: '100%',
      warningHeight: '132px',
    };
  },
  computed: {
    ...mapGetters('checkout', ['checkout']),
    canDisableButton() {
      return (this.messageIndex === 0 && this.customMessage === '')
        || (this.messageIndex < 0);
    },
    textAreaPlaceholder() {
      return 'Escreva aqui uma mensagem demonstrando todo seu carinho pelo noivos';
    },
    modalSubtitle() {
      if (this.greetingCardsEnabled) {
        return 'Personalize ou escolha a mensagem que será impressa no cartão lembrança';
      }
      return 'Personalize ou escolha a mensagem para o casal';
    },
  },
  watch: {
    customMessage() {
      this.messageIndex = 0;
    },
    showWarning(value) {
      this.ajustContentHeight(value);
    },
    isOpen(newVal) {
      if (newVal) {
        this.drawMessages(9);
      }
      if (newVal && this.isViewportMobile) {
        // eslint-disable-next-line
        history.pushState(null, null, '#');
      }
    },
  },
  created() {
    this.$parent.$on(this.listeningTo, this.openModal);
    this.$parent.$on('showMessageModal', this.showModal);
  },
  mounted() {
    this.ajustContentHeight(this.showWarning);
  },
  updated() {
    window.onpopstate = () => {
      if (this.isViewportMobile) {
        this.closeModal();
      }
    };
  },
  methods: {
    ...mapActions({
      getMessages: 'checkout/getMessages',
    }),
    handleHelp() {
      this.$emit('help');
      this.closeModal();
    },
    closeModal() {
      this.isOpen = false;
      this.overflowScrollBody();
    },
    openModal() {
      this.isOpen = true;
      this.overflowHideBody();
    },
    clickedInside(el, [x, y]) {
      const rect = el.getBoundingClientRect();
      return (rect.left <= x && x <= rect.right) && (rect.top <= y && y <= rect.bottom);
    },
    drawMessages(numMessages) {
      this.loading = true;
      const slug = this.checkout.weddingSlug;
      this.getMessages(slug).then((data) => {
        this.messages = data.weddingMessage;
        this.displayedMessages = this.randoms(numMessages, this.messages.length)
          .map((i) => this.messages[i]);
        this.loading = false;
      });
    },
    randoms(n, total) {
      const candidates = [];
      for (let i = 0; i < total; i++) {
        candidates.push(i);
      }
      const drawnNumbers = this.naturals(n).map(() => {
        const r = Math.random() * candidates.length;
        return candidates.splice(r, 1);
      });
      return drawnNumbers;
    },
    naturals(n) {
      return Array.from(Array(n).keys());
    },
    selectCustomMessage(msg) {
      this.messageIndex = 0;
      this.$refs.messageArea.focus();
      const target = this.$refs.modal;
      setTimeout(() => { target.scrollTop = 0; }, 500);
      this.customMessage = msg;
    },
    selectPickedMessage(index) {
      const msg = this.displayedMessages[index];
      this.messageIndex = index + 1;
      if (this.pickedMessage === msg) {
        this.pickedMessage = '';
        this.messageIndex = 0;
      } else {
        this.pickedMessage = msg;
      }
    },
    confirm() {
      this.trackingCheckoutChoseMessageFromModal();

      if (this.messageIndex === 0) {
        this.$emit('pickedMessage', this.customMessage);
      } else {
        this.$emit('pickedMessage', this.pickedMessage);
      }
      this.closeModal();
    },
    addCard() {
      this.closeModal();
      this.$emit('goToCards');
    },
    ajustContentHeight(displayWarning) {
      if (this.isViewportMobile) {
        this.contentHeight = 'auto';
      } else if (displayWarning) {
        this.contentHeight = `calc(100% - ${this.warningHeight})`;
      } else {
        this.contentHeight = '100%';
      }
    },
    handleOverlayClick(event) {
      if (event.target.classList.contains('modal-overlay')) {
        this.closeModal();
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.modal-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 20;
  width: 100%;
  background: rgba(0,0,0,0.6);

  @media(min-width: md) {
    height: 100vh;
  }

  .modal-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #ffffff;
    position: relative;
    width: 75%;
    height: 100vh;
    overflow-y: auto;

    .mobile-content-scroll{
      height:100%;
    }

    @media(max-width: md) {
      width: 100%;
    }

    @media(min-width: md) {
      height: 85vh;
      border-radius: 10px;
      overflow-y: visible;
    }

    &.full-screen {
      padding: 0;
    }

    &.widthSmall {
      width: 318px !important;
    }

    .warning {
      width: 100%;
      padding: 1.2rem;
      display: inline-block;
      background-color: #ff5050;
      text-align:center;

      @media (min-width: md) {
        border-radius: .5rem .5rem 0 0;
      }

      .warning-title {
        color: #ffffff;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.13;
        font-family: 'proxima-nova', sans-serif;
      }

      .warning-button {
        border-radius: .5rem;
        width: 40%;
        height: 2.6rem;
        background-color: #ffffff;
        color: #141414;
        font-size: 16px;
        font-weight: 600;
        font-family: 'proxima-nova', sans-serif;
        line-height: .1rem;
        margin-top: 1rem;

        @media(max-width: md) {
          width: 90%;
          max-width: 20rem;
        }
      }
    }

    .container {
      height: 100%;

      .contents{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        @media(min-width: md){
          height: 100%
        }

        .header {
          margin-top: 1.2rem;
          margin-bottom: 1.2rem;
          padding-left: 0.7rem;
        }

        .card-info{
          & > div:last-child{
            margin-bottom: 84px;
          }

          @media(min-width: md){
            overflow-y: auto;

            & > div:last-child{
              margin-bottom: 0;
            }
          }
        }

        .container-title {
          color: #141414;
          font-size: 24px;
          font-weight: 500;
          line-height: 1.21;
          font-family: 'proxima-nova', sans-serif;
          margin-top: 1.8rem;
          margin-bottom: 0.7rem;

          @media(max-width: md) {
            text-align: left;
            padding-left: 10px;
            margin-top:0;
          }
        }

        .container-subtitle {
          display: flex;
          justify-content: center;
          align-items: center;
          color: #141414;
          font-size: 14px;
          line-height: 1.43;
          font-family: 'proxima-nova', sans-serif;
          margin-bottom: 0.7rem;
          padding-bottom: 0.7rem;

          @media(max-width: md) {
            text-align: left;
            padding-left: 10px;
          }

          .subtitle-help{
            display:flex;
            align-items: center;
            justify-content: center;
            svg{
              margin-left: 5px;
              height: 15px;
            }
          }

        }

        .columns {
          .column {
            .message-container {
              position: relative;
              display: flex;
              align-items: center;
              border-bottom: 1px solid #dddddd;
              cursor: pointer;

              @media (min-width: md) {
                height: 80px;
              }
              @media (max-width: md) {
                padding: .8rem 0;
              }

              .input-check {
                position: absolute;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                border: 1px solid #d8d8d8;
                display: inline-flex;
                vertical-align:top;
                justify-content:center;
                align-items:center;
                background-color: #ffffff;
                border-color: #d8d8d8;

                &.selected {
                  background-color: #41bf24;
                  border-color: #41bf24;
                }

                svg {
                  fill: #ffffff;
                }
              }

              .message {
                text-align: left;
                padding-left: 1.6rem;
                width:100%;

                .custom-label {
                  color: #000000;
                  font-weight: bold;
                  text-align: left;
                }

                .custom-textarea {
                  border: none;
                  resize: none;
                  color: #808080;
                  font-size: 14px;
                  line-height: 1.36;
                  font-family: 'proxima-nova', sans-serif;
                  width: 100%

                  &:focus {
                    outline-offset: inherit;
                    outline: none;
                  }
                }
              }
            }
          }
        }

        .container-footer{

          @media (max-width: md) {
            position: fixed;
            bottom: 0;
            left: 0;
            background: white;
            width: 100%;
          }
          .confirm-button {
            border-radius: .5rem;
            background-color: var(--main-color);
            color: #ffffff;
            font-size: 16px;
            font-weight: 600;
            line-height: 1.25;
            font-family: 'proxima-nova', sans-serif;
            margin-top: 1rem;
            margin-bottom: 1.2rem;
          }
        }
      }

    }

    .content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .close-button {
      width: 2rem;
      height: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: -0.5rem;
      right: -0.5rem;
      background: #fff;
      border-radius: 100px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      cursor: pointer;

      svg {
        width: 0.7rem;
        margin: 0;
        fill: #707070;
      }
    }

    &.full-screen {
      width: 100%;
      max-width: 100%;
      border-radius: 0;
      height: 100vh;

      .close-button {
        top: 1rem;
        right: 1rem;
      }
    }
  }
  .loader {
    margin-top: 3rem;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
