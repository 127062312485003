import { render, staticRenderFns } from "./m-notifications-container.vue?vue&type=template&id=03e15b27"
import script from "./m-notifications-container.vue?vue&type=script&lang=js"
export * from "./m-notifications-container.vue?vue&type=script&lang=js"
import style0 from "./m-notifications-container.vue?vue&type=style&index=0&id=03e15b27&prod&lang=stylus"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports