<template>
  <div
    v-if="!$apollo.queries.registry.loading"
    class="checkout"
  >
    <div
      v-if="checkout.hasErrors"
      class="checkout__error-message"
    >
      <danger-svg/>
      <span>{{ errorMessage() }}</span>
    </div>
    <section
      :class="{'on-warning' : checkout.hasErrors}"
      class="checkout__couple-data"
    >
      <core-box
        is-container
        direction="row"
        class="checkout-header"
        align-items="center"
        justify-content="space-between"
      >
        <img
          :src="horizontalLogoPath()"
          class="logo"
        >
        <a
          :href="whatsAppLink"
          target="_blank"
          class="header__help"
        >

          <core-box
            direction="row"
            class="help"
          >
            <core-text
              color="main"
              weight="600"
            >Ajuda</core-text>
            <information-svg class="help__icon"/>
          </core-box>
        </a>
      </core-box>
    </section>
    <template v-if="hasHelp">
      <section
        v-if="isViewportDesktop"
        class="checkout__help-actions"
      >
        <div
          class="container grid-lg"
        >
          <div class="columns">
            <div class="column col-xs-12">
              <div class="help__whatsapp">
                <whatsapp-svg/>
                <div class="show-md">
                  <span>Precisa de ajuda?
                    <a
                      :href="whatsAppLink"
                      target="_blank"
                    ><strong>Clique aqui</strong></a>
                    pra tirar suas dúvidas.</span>
                </div>
                <div class="hide-md">
                  <span>
                    Precisa de ajuda?
                    <a
                      :href="whatsAppLink"
                      target="_blank"
                    ><strong>Clique aqui</strong></a>
                    que a gente tira todas as suas dúvidas!
                    Você também pode acessar nossa
                    <a
                      :href="helpLink"
                      target="_blank"
                    >
                      <strong>central de ajuda</strong>
                    </a>!
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>
    <section class="checkout__summary">
      <div class="container grid-lg">
        <div class="columns">
          <div class="column col-6 col-md-12">
            <div class="summary__container">
              <div class="checkout__message ">
                <core-text
                  type="heading"
                >
                  Insira seus dados
                </core-text>

                <core-box margin="2 0 0">
                  <div class="message__container">
                    <label for="guestName">
                      Quem está dando o presente?
                    </label>
                    <a-input
                      v-model="guest.name"
                      v-validate="'required'"
                      :error="errors.first('guestName')"
                      class="message__input"
                      name="guestName"
                      placeholder="João e Família"
                      data-vv-name="guestName"
                      required
                      borde-bottom
                    />
                  </div>
                  <div class="message__container">
                    <label for="guestEmail">
                      Qual o seu e-mail?
                    </label>
                    <a-input
                      v-model="guest.email"
                      v-validate="'required|email'"
                      :error="errors.first('guestEmail')"
                      class="message__input"
                      type="email"
                      name="guestEmail"
                      placeholder="joão@exemplo.com"
                      data-vv-name="guestEmail"
                      borde-bottom
                      required
                      @blur="handleBlur"
                    />
                  </div>
                  <div class="message__container">
                    <label for="mensagem">
                      Deixe uma mensagem especial
                    </label>
                    <a-textarea
                      v-model="guest.message"
                      v-validate="'required|max:600'"
                      :rows="rowNumber"
                      :error="errors.first('mensagem')"
                      class="message__input"
                      name="mensagem"
                      required
                      borde-bottom
                      placeholder="Escolha a sua mensagem"
                      style="height: auto;"
                      data-vv-name="mensagem"
                      @focus="handleTextAreaFocus($event)"
                    />
                  </div>
                  <a-button
                    v-if="isViewportDesktop"
                    @click="openPayment()"
                  >
                    Selecionar pagamento
                  </a-button>
                </core-box>
              </div>
            </div>
          </div>
          <div class="column col-md-12 col-6">
            <div class="summary__container card-box">
              <div class="summary__content">
                <div class="summary__header">
                  <a-avatar
                    :width="{default: '50px'}"
                    :avatar-name="coupleName"
                    :avatar-image="wedding.thumb_url"
                  />
                  <core-text
                    type="heading"
                    size="small"
                  >
                    Seus presentes para <wbr> {{ formattedCoupleName }}
                  </core-text>
                </div>
                <ul class="summary-list">
                  <li
                    v-for="(gift, index) in gifts"
                    :key="index"
                  >
                    <div class="close-button">
                      <close-svg
                        class="close-svg"
                        @click="removeGift()"
                      />
                    </div>
                    <div class="gift-name">
                      <core-text
                        size="small"
                        color="grey-medium"
                      >
                        {{ gift.name }}
                      </core-text>
                    </div>
                    <div class="price">
                      <core-text
                        size="small"
                        color="grey-medium"
                      >
                        {{ gift.total_value | toCurrency }}
                      </core-text>
                    </div>
                  </li>
                  <li
                    v-if="greetingCardsEnabled"
                    class="card"
                  >
                    <div class="card-content">
                      <div class="close-button">
                        <close-svg
                          v-if="currentCard.type !== ''"
                          class="close-svg"
                          @click="openPickCard(1)"
                        />
                      </div>
                      <div class="alter-text-options">
                        <core-text
                          size="small"
                          color="grey-medium"
                        >
                          {{ cardLabel }}
                        </core-text>
                        <span
                          class="change-card"
                          @click="openPickCard(0)"
                        >
                          Alterar
                        </span>
                      </div>
                      <div
                        v-if="discountStatus && currentCard.type == 'classic'"
                        class="card-price"
                      >
                        <core-text
                          size="small"
                          color="grey-medium"
                        >
                          {{ currentCard.price * 0.7 | toCurrency }}
                        </core-text>
                      </div>
                      <div
                        v-else
                        class="card-price"
                      >
                        <core-text
                          size="small"
                          color="grey-medium"
                        >
                          {{ currentCard.price | toCurrency }}
                        </core-text>
                      </div>
                    </div>
                    <div
                      v-if="displayTimer"
                      class="countdown"
                    >
                      <p>Desconto Cartão Clássico</p>
                    </div>
                  </li>
                  <li>
                    <div>
                      <core-text
                        size="small"
                        weight="600"
                      >
                        Total
                      </core-text>
                    </div>
                    <div>
                      <core-text
                        size="small"
                        weight="400"
                      >
                        {{ giftCartTotalValue | toCurrency }}
                      </core-text>
                    </div>
                  </li>
                </ul>
                <core-box
                  direction="row"
                  padding="4 6"
                  :margin="['4 0 0', '0']"
                  class="account__content"
                  align-items="center"
                  justify-content="space-between"
                  @click.native="openPayment()"
                >
                  <core-text
                    color="grey-medium"
                    size="small"
                  >
                    Parcele em até {{ paymentInstallments }}x no cartão
                  </core-text>
                  <payment-options-svg/>
                </core-box>
                <core-text
                  type="body"
                  size="small"
                  color="grey-medium"
                  style="margin-top: 8px;"
                >
                  Seus presentes serão convertidos em dinheiro. <a
                    href="https://ajuda.wedy.com/pt-BR/articles/6345761-como-funciona-a-lista-de-presentes-em-dinheiro-da-wedy"
                    target="_blank"
                    style="text-decoration: underline;"
                  >Clique aqui para entender mais.</a>
                </core-text>
              </div>
            </div>
          </div>
        </div>
        <a-sticky
          v-if="isViewportMobile"
          bottom
          bound-to-parent
          :z-index="9"
        >
          <core-box padding="4 0">
            <a-button
              block
              @click="openPayment()"
            >
              Selecionar pagamento
            </a-button>
          </core-box>
        </a-sticky>
      </div>
    </section>
    <m-loading
      v-show="loading"
      :loading-text="'Voltando...'"
      class="publisher-loading"
      tips
    />
    <m-payment-type
      v-if="isOpenPayment"
      :amount="giftCartTotalValue"
      :total-value-without-card="totalValueWithoutCard"
      @close="closePayment"
      @success="transactionCreated = true"
    />
    <o-success
      v-if="transactionCreated"
      :back-to="homeURL"
      @close="closeSuccess"
    />

    <o-pick-card
      v-if="isOpenPickCard"
      :cards="isSadCardInStart ? cardsInStart : cards"
      :current-cardindex="isSadCardInStart ? cardIndex : cardIndex "
      :sad-card-in-start="isSadCardInStart"
      :opening-step="pickCardStep"
      :couple-name="coupleName"
      :couple-image="coupleImage"
      @closePickCard="closePickCard"
      @selectedCard="selectedCard"
      @applyDiscount="applyDiscount"
    />
    <m-modal :modal-information="modalInformation">
      <template slot="icon">
        <heart-delivery-truck-svg/>
      </template>
    </m-modal>

    <m-message-modal
      :show-warning="isSadCardSelected"
      :greeting-cards-enabled="greetingCardsEnabled"
      listening-to="openMessageModal"
      @pickedMessage="changeMessage"
      @goToCards="openPickCard(0)"
    />
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import authentically from '@wedy/legacy-components/mixins/authentically';
import viewportly from '@wedy/legacy-components/mixins/viewportly';

import AInput from '@wedy/legacy-components/atoms/a-input.vue';
import AButton from '@wedy/legacy-components/atoms/a-button.vue';
import AAvatar from '@wedy/legacy-components/atoms/a-avatar.vue';
import ASticky from '@wedy/legacy-components/atoms/a-sticky.vue';
import ATextarea from '@wedy/legacy-components/atoms/a-textarea.vue';
import MModal from '@wedy/legacy-components/molecules/m-modal.vue';
import DangerSvg from '@wedy/legacy-assets/images/icons/danger.svg';
import WhatsappSvg from '@wedy/legacy-assets/images/icons/whatsapp.svg';
import HeartDeliveryTruckSvg from '@wedy/legacy-assets/images/icons/heart-delivery-truck.svg';
import PaymentOptionsSvg from '@wedy/legacy-assets/images/payment-logos/payment-options.svg';
import CloseSvg from '@wedy/legacy-assets/images/icons/close.svg';
import MLoading from '@wedy/legacy-components/molecules/m-loading.vue';
import MMessageModal from '@wedy/legacy-components/molecules/m-message-modal.vue';
import { toCurrency } from '@wedy/legacy-components/utils/masks';
import CoreBox from '@wedy/legacy-components/core/core-box.vue';
import CoreText from '@wedy/legacy-components/core/core-text.vue';
import InformationSvg from '@wedy/legacy-assets/images/icons/information.svg';
import MPaymentType from './m-payment-type.vue';
import OPickCard from './o-pick-card.vue';
import OSuccess from '#/views/checkout/o-success.vue';

export default {
  name: 'VCheckoutSummary',
  components: {
    AButton,
    AInput,
    ATextarea,
    ASticky,
    MModal,
    AAvatar,
    CoreBox,
    CoreText,
    DangerSvg,
    WhatsappSvg,
    HeartDeliveryTruckSvg,
    CloseSvg,
    PaymentOptionsSvg,
    MLoading,
    MMessageModal,
    MPaymentType,
    OPickCard,
    OSuccess,
    InformationSvg,
  },
  filters: {
    toCurrency(value) {
      return toCurrency(Number(value || 0));
    },
  },
  mixins: [authentically, viewportly],
  props: {
    giftCard: Object,
  },
  data() {
    return {
      gifts: [],
      showTimer: true,
      registry: {},
      transactionCreated: false,
      timerState: this.timer,
      slug: null,
      loading: false,
      isOpenPickCard: false,
      pickCardStep: 0,
      isOpenPayment: false,
      isSadCardInStart: false,
      cardIndex: 1,
      rowNumber: 3,
      guest: {
        name: '',
        email: '',
        message: '',
      },
      cardLabel: '',
      cards: [
        {
          name: 'Simples',
          type: 'simple',
          description: 'Um cartão simples que será entregue com muito carinho para o casal.',
          price: '15.90',
        },
        {
          name: 'Classic',
          type: 'classic',
          description: 'Um cartão memorável, para lembrar para sempre do seu presente.',
          price: '24.90',
        },
        {
          name: 'Premium',
          type: 'premium',
          description: 'Impresso em papel texturizado, para encantar o casal nesse momento tão especial.',
          price: '33.90',
        },
        {
          name: 'Nenhum',
          description: 'Selecione essa opção caso você não queira enviar um cartão lembrança para o casal.',
          price: '0.00',
          type: '',
        },
      ],
      cardsInStart: [
        {
          name: 'Nenhum',
          description: 'Selecione essa opção caso você não queira enviar um cartão lembrança para o casal.',
          price: '0.00',
          type: '',
        },
        {
          name: 'Simples',
          type: 'simple',
          description: 'Um cartão simples que será entregue com muito carinho para o casal.',
          price: '15.90',
        },
        {
          name: 'Classic',
          type: 'classic',
          description: 'Um cartão memorável, para lembrar para sempre do seu presente.',
          price: '24.90',
        },
        {
          name: 'Premium',
          type: 'premium',
          description: 'Impresso em papel texturizado, para encantar o casal nesse momento tão especial.',
          price: '33.90',
        },
      ],
    };
  },
  apollo: {
    registry: {
      query: gql`
        query($input: WebsiteInput!) {
          website (input: $input) {
            registry {
              isKeepsakeCardsEnabled
            }
          }
        }
      `,
      variables() {
        return {
          input: {
            slug: this.$route.params.slug,
          },
        };
      },
      update: (data) => data.website.registry,
    },
  },
  computed: {
    ...mapGetters('wedding', ['wedding']),
    ...mapGetters('checkout', ['checkout', 'checkoutGiftValue', 'checkoutGiftName', 'discountStatus', 'timer']),
    currentFormIsValid() {
      return Object.keys(this.fields)
        .every((field) => this.fields[field] && this.fields[field].valid);
    },
    displayTimer() {
      if (this.discountStatus && this.showTimer) {
        return true;
      }
      return false;
    },
    greetingCardsEnabled() {
      return Boolean(this.registry && this.registry.isKeepsakeCardsEnabled);
    },
    totalValueWithoutCard() {
      return this.gifts.reduce((sum, gift) => sum + parseFloat(gift.total_value), 0);
    },
    totalValue() {
      const totalValue = this.gifts.reduce((sum, gift) => sum + parseFloat(gift.total_value), 0);
      if (!this.greetingCardsEnabled) return totalValue;

      let cardPrice = parseFloat(this.currentCard.price);
      if (this.discountStatus && this.currentCard.type === 'classic') {
        cardPrice *= 0.7;
      }
      return totalValue + cardPrice;
    },
    giftCartTotalValue() {
      const total = this.totalValue;
      return this.fixDecimalValue(total);
    },
    formattedCoupleName() {
      return this.simpleName ? this.simpleName : '';
    },
    simpleName() {
      return this.wedding.simpleName;
    },
    coupleName() {
      return this.wedding.couple_name;
    },
    coupleImage() {
      return this.wedding.thumb_url;
    },
    currentCard() {
      const noCard = this.cards.find((card) => card.name === 'Nenhum');
      if (!this.greetingCardsEnabled) return noCard;
      return this.isSadCardInStart ? this.cardsInStart[this.cardIndex] : this.cards[this.cardIndex];
    },
    cardItems() {
      return this.gifts.map((gift) => (gift.uuid ? { uuid: gift.uuid, quotas: gift.quotas } : null))
        .filter((e) => e);
    },
    whatsAppLink() {
      const message = encodeURIComponent(`Oi ${this.currentInstance.name}, preciso de ajuda para realizar a compra de um presente para o casal ${this.coupleName}.`);
      return `https://api.whatsapp.com/send?phone=551132300573&text=${message}`;
    },
    modalInformation() {
      return {
        text: 'Faremos a entrega do cartão lembrança com a sua mensagem na casa do casal! E melhor, ',
        strong: 'a entrega é por nossa conta!',
      };
    },
    hasHelp() {
      return !!this.helpLink;
    },
    helpLink() {
      return this.currentInstance.publicContent.pages.help;
    },
    paymentInstallments() {
      return this.currentInstance.publicContent.gateway.max_installments;
    },
    isSadCardSelected() {
      return this.currentCard.price === '0.00';
    },
    hasMessage() {
      return this.guest.message !== '';
    },
    homeURL() {
      return `${this.wedding.site_url}`;
    },

  },
  watch: {
    cardIndex(newContent) {
      if (newContent === 0) {
        this.$root.$emit('stopTimer');
      } else {
        this.$root.$emit('stopTimer');
        this.$root.$emit('startTimer');
      }
    },
    transactionCreated(visible) {
      if (visible) this.closePayment();
    },
  },
  created() {
    this.setCardLabel();

    if (window.localStorage.getItem('discount') !== null) {
      this.addDiscountStatus();
      this.addCardDiscount(30);
    }
  },
  async mounted() {
    await this.resetCheckoutState();

    this.slug = this.$route.params.slug;
    this.gifts = this.$route.query.gifts;

    if (!this.gifts) {
      this.gifts = [{
        name: this.checkoutGiftName,
        total_value: this.checkoutGiftValue,
      }];
    }

    if (this.slug) {
      this.addWeddingSlug(this.slug);
    }

    if (this.cardItems) {
      this.cardItems.forEach((item) => this.addCartItem(item));
    }

    this.cardIndex = this.getDefaultCardByPrice();

    this.fetchConfig().then(() => {
      this.isSadCardInStart = (this.cardIndex === 2);
      if (this.cardIndex === 2) { this.cardIndex += 1; }
    });

    window.onpopstate = () => {
      if (this.isViewportMobile) {
        this.closePickCard();
      }
    };

    this.trackingCheckoutStarted(this.slug);

    this.$gtag.event('Checkout (legacy)');
  },
  methods: {
    ...mapMutations({
      addGuest: 'checkout/addGuest',
      addCardType: 'checkout/addCardType',
      addCartItem: 'checkout/addCartItem',
      addWeddingSlug: 'checkout/addWeddingSlug',
      addCardDiscount: 'checkout/addCardDiscount',
      addDiscountStatus: 'checkout/addDiscountStatus',
      addSimpleCardDiscount: 'checkout/addSimpleCardDiscount',
      addTimer: 'checkout/addTimer',
    }),
    ...mapActions({
      cleanCheckout: 'checkout/cleanCheckout',
      resetCheckoutState: 'checkout/resetCheckoutState',
      fetchConfig: 'checkout/fetchConfig',
    }),
    openHelp() {
      this.openPickCard(1);
    },
    removeGift() {
      // eslint-disable-next-line
      const confirmed = window.confirm('Você deseja voltar a Lista de Casamento e remover o item?')
      if (confirmed) {
        this.loading = true;
        window.location.href = `${this.wedding.site_url}/presentes`;
      }
    },
    errorMessage() {
      if (this.checkout.result.status === 'refused') {
        return 'A transação não foi autorizada pela operadora.';
      }
      return 'Algo aconteceu de errado e a compra não foi aprovada.';
    },
    fixDecimalValue(value) {
      return parseFloat(value).toFixed(2);
    },
    selectedCard(index) {
      this.cardIndex = index;
    },
    openPickCard(pickCardStep) {
      this.$gtag.event(pickCardStep === 0 ? 'Open card picker' : 'Removing card');

      this.pickCardStep = pickCardStep;
      this.isOpenPickCard = true;
      this.overflowHideBody();

      if (pickCardStep === 1) {
        this.trackingCheckoutIntentionToRemoveCardByCloseSvg();
      }

      if (this.isSadCardSelected) {
        this.cardIndex = this.getDefaultCardByPrice();
      }

      if (this.isViewportMobile) {
        // eslint-disable-next-line
        history.pushState(null, null, '#');
      }
    },
    closePickCard() {
      this.isOpenPickCard = false;
      this.overflowScrollBody();
      if (!this.isSadCardSelected) {
        this.openMessageModal();
      }
    },
    scrollAndfocusFirstInvalidInput() {
      const inps = [];
      const inputs = document.getElementsByClassName('box-input__field');
      const textareas = document.getElementsByClassName('box-textarea');
      for (let i = 0; i < inputs.length; i++) { inps.push(inputs[i].firstChild); }
      for (let i = 0; i < textareas.length; i++) { inps.push(textareas[i].firstChild); }
      const invalids = Object.keys(this.fields).map((key) => this.fields[key].invalid);
      const firstInvalidIndex = invalids.findIndex((f) => f === true);

      const el = inps[firstInvalidIndex];
      el.focus();
      el.scrollIntoView({ behavior: 'smooth' });
    },
    openPayment() {
      this.$gtag.event('Selecting payment');

      this.$validator.validate();

      if (this.currentFormIsValid) {
        this.cleanCheckout().then(() => {
          this.addGuest({
            name: this.guest.name,
            email: this.guest.email,
            message: this.guest.message,
          });
          this.addCardType(this.currentCard.type);

          this.isOpenPayment = true;
        });
      } else {
        this.scrollAndfocusFirstInvalidInput();
      }
    },
    closePayment() {
      this.isOpenPayment = false;
    },
    closeSuccess() {
      this.transactionCreated = false;
      window.location = this.homeURL;
    },
    getDefaultCardByPrice() {
      const SIMPLE_AVERAGE_TICKET_LIMIT = 90;
      const CLASSIC_AVERAGE_TICKET_LIMIT = 180;
      const price = this.totalValue;
      if (price <= SIMPLE_AVERAGE_TICKET_LIMIT) {
        return 0; // simple
      } if (price <= CLASSIC_AVERAGE_TICKET_LIMIT) {
        return 1; // classic
      }
      return 2; // premium
    },
    openModal() {
      this.$emit('showModal');
    },
    applyDiscount() {
      if (!this.discountStatus) {
        this.cards.map((card) => ({
          ...card,
          price: this.addSimpleCardDiscount(30, card),
        }));
        this.cardsInStart.map((card) => ({
          ...card,
          price: this.addSimpleCardDiscount(30, card),
        }));
      }
    },
    openMessageModal() {
      this.trackingCheckoutOpenedPickMessageModal();
      this.$emit('openMessageModal');
    },
    changeMessage(msg) {
      this.guest.message = msg;
    },
    handleBlur(email) {
      if (!this.errors.first('guestEmail')) {
        this.addGuest({ email });
      }
    },
    handleTextAreaFocus(event) {
      if (this.hasMessage === false) {
        event.blur();
        this.openMessageModal();
      }
    },
    horizontalLogoPath() {
      return this.instanceImages.logos.horizontal;
    },
    simpleLogoPath() {
      return this.instanceImages.logos.simple;
    },
    setCardLabel() {
      this.cardLabel = 'Cartão-lembrança';
    },
  },
};
</script>
<style lang="stylus" scoped>
.checkout {
  min-height: 100vh;
  padding-bottom: 6rem;

  @media (min-width: md) {
    background-color: #fff;
  }

  .checkout__error-message {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    background-color: #FFB125;
    position: fixed;
    width: 100%;
    padding: 1.5rem .4rem;
    z-index: 5;

    svg {
      width: 34px;
      fill: black;
      margin-right: .4rem;
    }
    span {
      font-size: 13px;
      font-weight: 500;
      line-height: 1.1;
      text-align: center;
      color: black;
    }
  }

  .checkout__couple-data {
    width: auto;
    height: auto;
    background-color: #fff;
    color: #ffffff;

    &.on-warning {
      padding-top: 50px;

      @media (min-width: md) {
        padding-top: 40px;
      }
    }

    .checkout-header {
      width: auto;
      height: auto;
      margin: 1.56rem 0;

      .logo{
        width: 90px;
      }

    }

    .mobile-header {
      width: auto;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .couple-data-header {
        width: auto;
        height: auto;
        display: flex;
        align-items: center;
        padding: 0.5rem 0;

        .couple__text {
          margin-left: .5rem;

          .text__subtitle {
            font-size: 12px;
            font-weight: 500;
            line-height: 1.17;
            letter-spacing: 0.6px;
            color: #ffffff;
            margin-bottom: .2rem;
          }

          h1 {
            font-size: 1.1rem;
            margin: 0;
            margin-bottom: .15rem;
          }

          span {
            font-size: 12px;
            font-weight: 500;
            line-height: 1.17;
            letter-spacing: 0.6px;
            color: #ffffff;
          }
        }
      }

      .wedy__icon {
        width: 2.1rem;
        height: 2.1rem;
      }
    }
  }

  .help {
    gap: 8px;
    .help__icon {
      fill: var(--main-color);
    }
  }

  .checkout__help-actions {
    background-color: var(--grey-xlight);
    color: var(--grey-medium);
    padding: 1.2rem 0;
    font-size: .75rem;
    font-weight: 500;
    letter-spacing: 0.1px;
    font-size: 14px;

    .help__whatsapp {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        fill: #fff;
        margin-right: .5rem;
      }
    }
  }

  .checkout__summary {
    color: #000;
    padding-top: .5rem;
    padding-bottom: 1rem;
    border-radius: 22px;

    @media (min-width: md) {
      box-shadow: none;
      margin-top: 2.5rem;
      padding-bottom: .6rem;

      .summary__container{
        display:flex;
        flex-direction: column;
      }

      .checkout__message {
        filter: drop-shadow(0px 15px 30px rgba(20, 102, 204, 0.16));
      }

    }

    .summary__content {

      @media (min-width: md) {
        margin-bottom: 1rem;
        padding: 2rem;
      }

      .summary__header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 15px

        .summary__header-text {
          display: flex;
          flex-direction: column;
        }

        @media (min-width: md) {
          justify-content: flex-start
          padding-top: 0
        }

        .summary__title {
          font-weight: 500;
          margin-top: 0;
          margin-bottom: 0;
          margin-left: .5rem;
          margin-right: 0.5rem;
          line-height: 1.11;

          @media (min-width: md) {
            margin-top: .5rem;
            margin-bottom: .5rem;
          }

          .title-icon {
            width: 1.6rem;
            fill: var(--main-color);
            margin-right: .6rem;
          }
        }

        .summary__logo{
          .logo{
            width: 32px;
          }
        }
      }

      .summary-list {
        color: #000;
        margin: 0;
        list-style: none;
        padding: 0;
        font-weight: 500;
        margin-top: 1rem;

        li {
          display: flex;
          justify-content: space-between;
          padding: .6rem 0;

          &:last-child {
            border-bottom: 0;
            padding-left: 10px;
          }

          &.card {
            flex-direction: column
          }

          .price, .close-button{
            display: flex;
            align-items: center;
          }

          .gift-name{
            flex-grow:1
            margin-left:12px;
          }

          .close-svg {
            fill: #cacaca;
            width: .5rem;
            margin-left: .5rem;
            transition: all 0.3s;

            &:hover {
              cursor: pointer;
              fill: #f0112b
            }
          }
        }
      }

      .card-content {
        border-top: 1px solid #EFEEEE;
        border-bottom: 1px solid #EFEEEE;
        padding: 1rem 0;
        display: flex;
        justify-content: space-between;

        .alter-text-options {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-grow: 1;
          margin-left: 12px;

          .change-card {
            color: #9098B1;
            border-radius: 5px;
            text-transform: uppercase;
            font-size: .75rem;
            margin-left: .5rem;
            cursor: pointer;
            transition: opacity .3s;
            font-weight: 600;

            &:hover {
              opacity: .7;
            }
          }
        }

        .card-price {
          display: flex;
          align-items: center;
        }

      }

      .free {
        color: #22b222;
        font-weight: 600;
        display: flex;
        justify-content: space-between;
        line-height: 2.5em;

        .free-shipping {
          display: flex;
          align-items: center;

          svg {
            margin-right: .5rem;
          }

          .question-svg {
            cursor: pointer;
            margin-left: .5rem;
            width: 1rem;
          }
        }
      }
    }
  }

  .summary__container{
    &.card-box{
      position: relative
    }

    &.card-box::after{
      border-radius: 10px;
      content: " ";
      height: 30px;
      position: absolute;
      left: -25px;
      bottom: 0;
      width: 100vw

      @media (min-width: md){
        box-shadow: 0 11px 14px -5px rgba(0,0,0,0.05);
        content:none;
      }
    }
    .checkout__message {
      background-color: #fff
      padding: 0;
      background: #fff;
      border-radius: 10px;
      height: 100%;

      @media (min-width: md){
        box-shadow: 0 0 10px 0 rgba(0,0,0,0.05);
        padding: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly
      }

      .message__container{
        label {
          font-weight: 600;
          color: #5d5d5d;
        }

        &:first-child{
          margin-top: 20px;
        }

        .message__input{
          padding-bottom: 0px;
          margin-bottom:  1.2rem;

          & >>> textarea{
            height: auto;
            box-shadow: none;
            resize: none;
            margin-bottom:  0.8rem;
            padding-left: 10px;
            font-style: normal;
          }
        }
      }

      .open-message-modal-button {
        color: var(--main-color);
        font-weight: 600;
        border: solid 1px var(--main-color);
        width: 100%;
        font-size: 1rem;
        margin-top: 1.2rem;
      }
    }
  }

  .pick-message-modal {

    width: 100%;

    /deep/ .modal-content {
      padding: 2rem 2rem;
    }

    .title {
      font-size: 1.15rem;
      font-weight: 500;
      line-height: 1.33;
      color: #141414;
      margin-bottom: 2rem;
    }

    .text {
      margin-bottom: 1.5rem;
      font-size: 0.875rem;
      line-height: 1.57;
      text-align: center;
      color: #757575;
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-height: 50%;
      overflow-y: auto;

      .message {
        /deep/ span {
          white-space: initial !important;
        }
      }
    }
  }
  .account__content {
    background-color: var(--grey-xlight);
    border-radius: 10px;
    svg {
      width: 110px;
    }
    @media (min-width: md){
      svg {
        width: 140px;
      }
    }
  }
}
.countdown {
  color: var(--grey-xlight);
  margin: 10px 0px 0;
  background-color: var(--main-color-cta);
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 0.35rem;
  max-width: 200px;

  & .timer {
    font-size: 1rem;
  }

  & p {
    font-size: .9rem;
    margin: 8px 0px;
  }
}
</style>
